import React from 'react';
import Showdown from 'showdown';
import Sanitizer from 'sanitize-html';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import SEO from '../components/atoms/SEO';
import MartaImage from '../assets/marta.svg';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWrapper = styled.section`
  width: 100%;
  max-width: 126rem;
  padding: 4rem 3rem;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-right: 30rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding-right: 20rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-right: 10rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding-right: 0rem;
  }
`;

const StyledContentWrapper = styled.article`
  color: ${({ theme }) => theme.font.color.main};

  p {
    line-height: 2.25rem;
    font-size: 1.5rem;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      line-height: 2.4rem;
      font-size: 1.6rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      line-height: 2.7rem;
      font-size: 1.8rem;
    }

    strong {
      margin: 3rem 0;
    }
    em {
      font-size: 2.5rem;
      color: ${({ theme }) => theme.font.main};
      position: relative;
      margin-bottom: 2rem;
      font-style: normal;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.m}) {
        font-size: 3rem;
      }
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.l}) {
        font-size: 3.5rem;
      }

      ::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 3.3rem;
        height: 0.3rem;

        background-color: ${({ theme }) => theme.font.color.accent};
        @media only screen and (min-width: ${({ theme }) =>
            theme.breakpoints.m}) {
          width: 4.3rem;
          height: 0.35rem;
        }
        @media only screen and (min-width: ${({ theme }) =>
            theme.breakpoints.l}) {
          width: 4.8rem;
          height: 0.4rem;
        }
      }
    }
    a {
      color: ${({ theme }) => theme.font.color.accent};
      font-weight: bold;
    }
  }

  blockquote {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    p {
      font-weight: bold;
      color: ${({ theme }) => theme.font.color.main};
    }

    em {
      font-family: ${({ theme }) => theme.font.family.cursive};
      font-size: 3rem;
    }
  }

  ul {
    padding-left: 2.5rem;
    margin: 2rem 0;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      padding-left: 3rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      padding-left: 3.5rem;
    }

    li {
      line-height: 2.25rem;
      font-size: 1.5rem;
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.m}) {
        line-height: 2.4rem;
        font-size: 1.6rem;
      }
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.l}) {
        line-height: 2.7rem;
        font-size: 1.8rem;
      }
      em {
        font-style: normal;
        font-weight: bold;
        color: ${({ theme }) => theme.font.color.main};
        border-bottom: 2px solid ${({ theme }) => theme.font.color.main};
      }
    }
  }
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 60rem;
  max-height: 35rem;
`;

const StyledAuthorWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25%;
  margin-top: 4rem;

  p {
    width: 12rem;
    font-size: 2rem;
    margin-left: 2rem;
  }
`;

const StyledAuthorImageWrapper = styled.figure`
  width: 7rem;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const data = graphql`
  query BlogQuery($id: String!) {
    datoCmsBlog(id: { eq: $id }) {
      heading
      content
      thumbnail {
        url
      }
    }
  }
`;

interface Props {
  data: {
    datoCmsBlog: {
      heading: string;
      content: string;
      thumbnail: {
        url: string;
      };
    };
  };
}

const BlogTemplate: React.FC<Props> = ({ data }) => {
  const {
    heading,
    content,
    thumbnail: { url },
  } = data.datoCmsBlog;
  const html = new Showdown.Converter().makeHtml(content);
  const sanitizedHTML = Sanitizer(html);
  return (
    <>
      <SEO title={heading} description={content} />
      <StyledContainer>
        <StyledWrapper>
          <StyledContentWrapper
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          ></StyledContentWrapper>
          <StyledImageWrapper>
            <StyledImage src={url} />
          </StyledImageWrapper>

          <StyledAuthorWrapper>
            <StyledAuthorImageWrapper>
              <img src={MartaImage} />
              <p>Marta Grzeminska</p>
            </StyledAuthorImageWrapper>
          </StyledAuthorWrapper>
        </StyledWrapper>
      </StyledContainer>
    </>
  );
};

export default BlogTemplate;
